import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h4><span>&bull;</span> {education.school}</h4>
          <p className="info">
            {education.degree} {(() => {
              return education.degree && education.graduated ? (<span>&bull;</span>) : null
          })()}
            <em className="date">{education.graduated}</em>
            <p className="date">{education.description}</p>
          </p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.id}>
          <h4><span>&bull;</span> {work.description}</h4>
        </div>
      );
    });

    const skills = this.props.data.skills.map((skills) => {
      return (
          <div key={skills.id}>
            <h4><span>&bull;</span> {skills.description}</h4>
          </div>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Образоваение</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Опыт работы</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Специализации</span>
              </h1>
            </div>

            <div className="nine columns main-col">{skills}</div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
