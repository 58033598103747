import React, {Component} from "react";
import {Fade, Slide} from "react-reveal";
import axios from "axios";
import {Form, Select, Input, Radio, Button} from 'antd';


const {Option} = Select;
const {TextArea} = Input;

class Contact extends Component {
    async componentDidMount() {
        this.setState({dates: await this.getDates()});
        document.title = "Психолог Валерия Лонберг";
    }

    constructor(props) {
        super(props);

        this.state = {
            dates: [],
            successMessage: "",
            errorMessage: "",
        }
    }

    onFinish = async (values) => {
        let ac = axios.create({
            baseURL: "https://consultationbackend.azurewebsites.net/",
            headers: {
                "Content-type": "application/json"
            }
        });
        let data = {
            DesiredTimeSlot: values.DesiredTimeSlot,
            ConsultInfo: {
                Platform: parseInt(values.Platform),
                Contact: values.Contact,
                Message: values.Message,
                Single: values.Single
            }
        };
        try {
            let response = await ac.post("/consults", data);
            if (response.data === false) {
                this.setState({errorMessage: "Вы можете создать только 1 запись в день!"});
                return;
            }
        } catch (e) {
            console.log(e);
            this.setState({errorMessage: e})
        }

        this.setState({successMessage: "Запись сделана!"});
    };

    async getDates() {
        let ac = axios.create({
            baseURL: "https://consultationbackend.azurewebsites.net/",
            headers: {
                "Content-type": "application/json"
            }
        });
        let response = await ac.get("/consults/slots");
        let dates = [];
        response.data.sort((a, b) => {
            return new Date(a) - new Date(b);
        });

        for (let i = 0; i < response.data.length; ++i) {
            dates.push(response.data[i]);
        }

        return dates;
    }

    render() {
        if (!this.props.data) return null;

        const message = this.props.data.contactmessage;
        const {dates} = this.state;
        return (
            <section id="contact">
                <Fade bottom duration={1000}>
                    <div className="row section-head">
                        <p className="lead center">{message}</p>
                    </div>
                </Fade>

                <div className="row center">
                    <Slide left duration={1000}>
                        <div className="eight columns">
                            <Form className={"Form-Div"} onFinish={this.onFinish}>
                                <Form.Item id={"timeSlot"}
                                           name="DesiredTimeSlot"
                                           label="Выберите дату и время:"
                                           rules={[
                                               {
                                                   required: true
                                               }
                                           ]}
                                >
                                    <Select placeholder="Выберите дату и время консультации">
                                        {dates.map(item => (
                                            <Option value={item.toString()}>
                                                {new Intl.DateTimeFormat('ru-RU', {
                                                    dateStyle: 'full',
                                                    timeStyle: 'medium'
                                                }).format(new Date(item))}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="Platform"
                                    label="Платформа:"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Select placeholder="Где вам удобно провести консультацию">
                                        <Option value="0">Skype</Option>
                                        <Option value="1">Telegram</Option>
                                        <Option value="2">Viber</Option>
                                        <Option value="3">Оффлайн встреча</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="Contact"
                                    label="Ваш контакт"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                    <Input placeholder="Как вас найти на выбранной платформе"/>
                                </Form.Item>
                                <Form.Item
                                    name="Message"
                                    label="Сообщение">
                                    <TextArea rows={4} placeholder="Что мне стоит знать до встречи с вами"/>
                                </Form.Item>
                                <Form.Item
                                    name="Single"
                                    label="Я буду:"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={true}>Один</Radio>
                                        <Radio value={false}>С партнёром</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <div className={"center"}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size={"large"}>
                                            Записаться
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                            {(() => {
                                return this.state.errorMessage ? <div id="message-warning">{this.state.errorMessage}</div> : null
                            })()}
                            {(() => {
                                return this.state.successMessage ?
                                    (<div id="message-success">
                                        <i className="fa fa-check"></i>{this.state.successMessage}
                                        <br/>
                                    </div>)
                                    : null
                            })()}

                        </div>
                    </Slide>
                </div>
            </section>
        );
    }
}

export default Contact;
